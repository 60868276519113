@import '../../../styles/variables';

.order-history-card {
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 10px;
  .information-summary-container {
    flex-direction: row;
    justify-content: space-between;
    .information-summary {
      width: 40%;
      flex-direction: row;
      gap: 10px;
      .info {
        gap: 5px;
      }
      .points-tag {
        border-radius: 2px;
        padding: 2px 8px;
      }
      @include media-breakpoint-down(md) {
        width: 60%;
      }
    }
    .button-container {
      > section > div > .cta-button {
        min-height: 45px;
        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 45px;
          width: 156px;
          font-family: var(--font);
          font-size: 14px;
          line-height: 20px;
          padding: 5px var(--spacing-unit);
        }
      }
    }
  }
  .order-details {
    border-top: 1px solid var(--secondary-light);
    padding-top: var(--spacing-unit);
    .order-summary-image-container {
      margin-top: var(--spacing-unit);
      .order-summary-images {
        width: inherit;
        gap: 20px;
        .image-container {
          position: relative;
          border: 1px solid var(--secondary-primary);
          border-radius: 8px;
          .quantity-tag {
            z-index: 1;
            position: absolute;
            width: 28px;
            height: 28px;
            top: -13px;
            right: -10px;
            border-radius: 50%;
            border: 1px solid var(--white);
          }
        }
      }
      .item-image-overflow {
        border-radius: 50%;
        background-color: var(--secondary-primary);
        border: 1px solid var(--secondary-primary);
        height: 40px;
        width: 40px;
        text-align: center;
        margin: auto 0;
        padding-top: 4px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    padding: var(--spacing-unit);
    .information-summary-container {
      flex-direction: column;
      gap: 10px;
      .information-summary {
        width: 100%;
      }
    }
  }
}
