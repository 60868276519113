@import '../../styles/variables';

.cart-summary {
  .clear-cart-container {
    width: calc(100% - 338px - 30px);
    .clear-cart {
      width: fit-content;
    }
  }
  .summary-container {
    gap: 25px;
    .tiles {
      gap: 25px;
      width: calc(100% - 338px - 25px);
    }
    .order-summary {
      width: 338px;
      height: fit-content;
      border: 1px solid var(--secondary-primary);
      border-radius: 16px;
      .hairline {
        border-top: 1px solid var(--secondary-primary);
        margin: 10px 0;
      }
      .points {
        .points-tag {
          border-radius: 2px;
          padding: 2px 8px;
          height: 24px;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .header {
      margin-bottom: 35px;
      text-align: center;
    }
    .clear-cart-container {
      width: calc(100% - 5px);
    }
    .summary-container {
      flex-direction: column;
      gap: 35px;
      .tiles,
      .order-summary {
        width: 100%;
      }
      .tiles {
        gap: 20px;
      }
    }
  }
}
