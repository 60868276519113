@import '../../styles/variables';
@import '../Account/Drawer/account-drawer.scss';

.pac-container > .pac-item > .pac-item-query {
  font-size: 16px;
  .pac-matched {
    font-family: var(--font-bold);
    font-weight: unset;
  }
}

.store-drawer {
  @extend .account-sidebar;
  .close-container {
    width: 100%;
  }
  .store-drawer-search-panel > div {
    justify-content: space-between;
    > div {
      width: 100%;
    }
    .search-input-wrapper {
      width: 100%;
      .search-input-col {
        margin-right: 8px;
        border: 1px solid var(--secondary-primary);
        border-radius: 4px;
        padding: 12px var(--spacing-unit);
        height: 52px;
        width: 100%;
        > div {
          width: 100%;
        }
        .search-input {
          width: 100%;
          border: none;
          padding: 8px 10px;
          font-family: var(--font);
          font-size: 14px;
          line-height: 20px;
        }
      }
      .cta-wrapper {
        .cta-button-curaleaf {
          min-height: 52px;
          .btn {
            font-family: var(--font-bold);
            font-size: 14px;
            line-height: 20px;
            height: 52px;
            border-radius: 4px;
            padding: 5px 20px;
          }
        }
      }
    }
    .current-location-text {
      color: var(--primary);
      &:hover {
        color: var(--orange);
      }
    }
  }
}

.store-drawer-list {
  position: relative;
  gap: var(--spacing-unit);
  padding-bottom: 100px;
}

.p-sidebar-bottom .p-sidebar {
  height: 85% !important;
}
.p-sidebar {
  .p-sidebar-header {
    .p-sidebar-icon {
      display: none;
    }
  }
  .p-sidebar-content {
    padding: 0;
  }
}
