@import '../../../styles/variables';
@import '../../../templates/Account/account-preferences.scss';

.order-history-sidebar {
  &.p-sidebar {
    max-width: 480px;
    width: 100% !important; //override primereact;
  }
  &.p-sidebar {
    .p-sidebar-header {
      display: none;
    }
    .p-sidebar-content .close-container {
      padding: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 2)
        var(--spacing-unit);
      .close-icon {
        background: transparent;
        border: none;
        height: 24px;
        width: fit-content;
        padding-inline: 0;
      }
    }
  }
  .sidebar-header {
    padding: 0 calc(var(--spacing-unit) * 2);
    @include media-breakpoint-down(sm) {
      padding: 0 var(--spacing-unit);
    }
  }
  .information-summary {
    padding: 0 calc(var(--spacing-unit) * 2);
    margin-bottom: 10px;
    width: 100%;
  }
  .order-status-information {
    border-top: 1px solid var(--secondary-primary);
    border-bottom: 1px solid var(--secondary-primary);
    padding: var(--spacing-unit) 0;
    margin-bottom: var(--spacing-unit);
  }
  .status-btn {
    width: fit-content;
    height: 28px;
    display: flex;
    align-items: center;
    font-size: 14px;
    background-color: var(--white);
    border-radius: 8px;
    margin: 12px 25px 0;
    cursor: pointer;
    .pi-check {
      font-size: 14px;
      margin-right: 3px;
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
  .tile-container {
    gap: var(--spacing-unit);
  }
  .order-total-wrapper {
    margin-top: var(--spacing-unit);
  }
  .order-total-wrapper > .order-total {
    background-color: var(--white);
    border: 1px solid var(--secondary-primary);
    width: 100%;
    padding: calc(var(--spacing-unit) * 2);
    .hairline {
      border-top: 1px solid var(--secondary-primary);
      margin: 10px 0;
    }
    .indent-line {
      margin-left: var(--spacing-unit);
    }
  }
}
