@import '../../styles/variables';

.Breadcrumbs {
  flex-wrap: nowrap;
  white-space: nowrap;
  ul {
    padding: 0;
    .link-container {
      .slash {
        padding: 0 8px;
      }
      &:last-child {
        overflow: hidden;
        .breadcrumb-name {
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .slash {
          display: none;
        }
      }
      &:hover > a {
        opacity: 0.5;
        text-decoration: underline;
      }
    }
  }
  padding: calc(var(--spacing-unit) * 2);
  @media (max-width: 1023px) {
    // pgp mobile viewport
    padding: var(--spacing-unit) calc(var(--spacing-unit) * 2);
  }
  @include media-breakpoint-down(sm) {
    padding: var(--spacing-unit);
  }
}
