@import '../../styles/variables';

.cta-button-curaleaf {
  min-height: 33px;
  &[data-full-width='true'] {
    .btn {
      width: 100%;
    }
  }
  .btn {
    display: inline-block;
    text-decoration: none;
    text-align: center;
    font-family: var(--font-bold);
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
    &:focus {
      outline: none;
      box-shadow: var(--outline);
    }
    &:disabled {
      opacity: 0.65;
    }
  }
  .sm {
    border-radius: 4px;
    font-size: 12px;
    line-height: 18px;
    height: 33px;
    padding: 6px var(--spacing-unit);
  }
  .lg {
    border-radius: 16px;
    height: 56px;
    font-size: 16px;
    line-height: 24px;
    padding: 14px calc(var(--spacing-unit) * 2);
  }
  .cta-button-primary,
  .cta-button-secondary,
  .cta-button-quaternary {
    color: var(--white);
    background-color: var(--primary);
    border: 2px solid var(--primary);
    &:hover,
    &:active,
    &:focus {
      opacity: 0.5;
    }
  }
  .cta-button-tertiary {
    color: var(--primary);
    border: 2px solid var(--primary);
    background-color: var(--white);
    &:hover,
    &:focus,
    &:active {
      opacity: 0.5;
    }
  }
  .cta-button-quintenary {
    color: var(--white);
    border: 2px solid var(--white);
    background-color: var(--primary);
    &:hover,
    &:focus,
    &:active {
      opacity: 0.5;
    }
  }
  .cta-button-account {
    cursor: pointer;
    width: 100%;
    height: 56px;
    font-family: var(--font);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 2px solid var(--primary);
    background-color: var(--primary);
    color: var(--white);
    &:hover {
      opacity: 0.5;
    }
  }
  .cta-button-disabled {
    cursor: default;
    border: 2px solid var(--primary);
    background-color: var(--primary);
    opacity: 0.8;
    color: var(--white);
    pointer-events: none;
  }

  @include media-breakpoint-down(sm) {
    &[data-full-width-mobile='true'] {
      min-width: 100%;
      .btn {
        width: 100%;
      }
    }
  }
}
