@import '../../styles/variables';

.store-card {
  max-width: 420px;
  gap: 7px;
  border: 1px solid var(--secondary-primary);
  border-radius: 4px;
  padding: var(--spacing-unit);
  .store-info {
    gap: 7px;
    .address {
      font-style: normal;
    }
    .tags {
      gap: 5px;
      .tag {
        padding: 4px 8px;
        border-radius: 4px;
        &[data-delivery-tag='true'] {
          background-color: var(--specialty-primary);
          color: var(--primary);
        }
        &[data-delivery-tag='false'] {
          background-color: var(--primary);
          color: var(--white);
        }
      }
    }
  }
  .button-group {
    .cta-button {
      width: 50%;
      a {
        width: 100%;
      }
    }
  }
  &[data-selected='true'] {
    position: relative;
    border-color: var(--primary);
    .selected-icon {
      position: absolute;
      right: -7px;
      top: -7px;
    }
  }
  @include media-breakpoint-down(sm) {
    max-width: unset;
  }
}
