@import '../../styles/variables';

.search-panel {
  background-color: var(--tertiary-light);
  .search-header {
    margin: auto;
    margin-bottom: calc(var(--spacing-unit) * 2);
  }
  .search-container {
    padding: 0;
    width: 100%;
    .search-input-wrapper {
      flex-direction: column;
    }
    .search-input {
      margin-right: var(--spacing-unit);
      white-space: nowrap;
      min-height: 60px;
      input {
        border-radius: 6px;
        padding: 0 var(--spacing-unit);
        width: 100%;
        border: 1px solid var(--input-normal);
      }
    }
    .cta-wrapper {
      .cta-button-curaleaf {
        margin-top: var(--spacing-unit);
      }
    }
  }
  .use-current-location {
    margin-top: var(--spacing-unit);
    cursor: pointer;
    .current-location-text {
      margin-left: 8px;
      text-decoration: underline;
    }
    &:hover {
      svg > path {
        fill: var(--input-normal);
      }
      .current-location-text {
        color: var(--input-normal);
      }
    }
  }
}
@include media-breakpoint-up(md) {
  .search-panel {
    background-color: inherit;
    .search-header {
      max-width: 50%;
    }
    .search-container {
      padding: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 2);
      background-color: var(--tertiary-light);
      display: inline-block;
      border-radius: 10px;
      .search-input-wrapper {
        flex-direction: row;
      }
      .search-input {
        margin-right: 0;
      }
      .cta-wrapper {
        padding: 0 10px;
        .cta-button-curaleaf {
          margin-top: 0;
          min-width: unset;
          width: 100%;
          max-width: 100%;
          .cta-button-secondary {
            padding: 5px;
            width: 100%;
          }
        }
      }
    }
    .use-current-location {
      svg {
        transform: scale(1.5);
      }
      // FOR locations/[state]
    }
  }
}

.condensed {
  padding: 1rem !important;
}
