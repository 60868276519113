@import '../../styles/variables';

.chatbox-tab {
  position: fixed;
  bottom: 6rem;
  right: 0;
  z-index: 102;
  svg {
    height: 50px;
    width: 50px;
  }
  @media (max-width: 1023px) {
    bottom: 7rem;
    svg {
      height: 34px;
      width: 34px;
    }
  }
}
