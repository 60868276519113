.ecom-button-curaleaf {
  .btn {
    cursor: pointer;
    width: 100%;
    height: 56px;
    font-family: var(--font-bold);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    padding: 14px calc(var(--spacing-unit) * 2);
    &:disabled {
      background: var(--disabled);
      border: 4px solid var(--disabled);
      cursor: default;
    }
  }
  .pi-spinner {
    font-size: 20px;
    margin-right: 10px;
  }
  .ecom-button-primary,
  .ecom-button-secondary,
  .ecom-button-quaternary {
    @extend .btn;
    color: var(--white);
    background-color: var(--primary);
    border: 1px solid var(--primary);
    &:hover:enabled,
    &:active:enabled {
      opacity: 0.5;
    }
  }
  .ecom-button-tertiary {
    color: var(--primary);
    border-color: var(--primary);
    background-color: var(--white);
    &:hover,
    &:active {
      opacity: 0.5;
    }
  }
}
