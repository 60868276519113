@import '../../../styles/variables';

.information-summary {
  width: 40%;
  flex-direction: row;
  gap: 10px;
  .info {
    gap: 5px;
  }
  .points-tag {
    border-radius: 2px;
    padding: 2px 8px;
  }
  @include media-breakpoint-down(md) {
    width: 60%;
  }
}
