@import '../../styles/variables';

.account-preferences-wrapper {
  min-height: calc(100vh - 666px);
  @include media-breakpoint-down(sm) {
    min-height: calc(100vh - 908px);
  }
}

.account-preferences {
  height: 100%;
  padding: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 2)
    calc(var(--spacing-unit) * 6);
  @include media-breakpoint-down(sm) {
    padding: var(--spacing-unit) var(--spacing-unit)
      calc(var(--spacing-unit) * 2);
  }
  .account-heading {
    text-align: center;
    margin-bottom: var(--spacing-unit);
    @include media-breakpoint-down(sm) {
      text-align: left;
    }
  }
  .cta-button-secondary {
    margin-top: calc(var(--spacing-unit) * 2);
  }
  .personal-info-wrapper,
  .preferences-wrapper,
  .loyalty-status-wrapper {
    border: 1px solid var(--secondary-primary);
    border-radius: 16px;
    margin: auto;
    padding: calc(var(--spacing-unit) * 2);
    max-width: 996px;
    width: 100%;
    .account-loader {
      height: 550px;
      position: relative;
      > section,
      div {
        height: 100%;
      }
    }
    @include media-breakpoint-down(sm) {
      padding: var(--spacing-unit);
    }
    .change-pw > a {
      cursor: pointer;
      text-decoration: underline;
      display: block;
    }
  }
  .loyalty-status-wrapper {
    .account-loader {
      height: 560px;
    }
  }
  .order-history-wrapper {
    .account-loader {
      height: 200px;
    }
  }
  .preferences-wrapper {
    margin-top: var(--spacing-unit);
    .preferences-form {
      > section {
        margin-bottom: var(--spacing-unit);
      }
      .button-group {
        margin-top: var(--spacing-unit);
        > section:first-of-type {
          width: 177px !important;
          margin-right: var(--spacing-unit);
          .cta-button-tertiary {
            height: 56px;
            padding: 4px calc(var(--spacing-unit) * 4);
          }
        }
        > section:last-child {
          button {
            width: 100%;
          }
        }
      }
    }
    .optin-checkbox-container {
      .checkbox-container-curaleaf {
        margin-left: var(--spacing-unit);
      }
    }
  }
}
@mixin column-width($width) {
  flex: $width;
}

@for $i from 1 through 12 {
  .column-#{$i} {
    @include column-width($i);
  }
}

.column-container {
  gap: 40px;
  .column {
    flex: 5;
  }
}
.divider-parent {
  height: 20px;
  margin: 24px 0;
  .divider-text {
    position: relative;
    width: 100%;
  }
  .divider-text::before,
  .divider-text::after {
    content: '';
    flex-grow: 1;
    background: var(--light-grey);
    height: 2px;
  }
  .divider-text::before {
    margin-right: 4px;
  }
  .divider-text::after {
    margin-left: 4px;
  }
}
.table {
  .table-item {
    padding: 12px 20px;
    .tag {
      padding: 0 8px;
      height: 30px;
      line-height: 30px;
      margin: auto 0;
    }
  }
  .show-more-container {
    height: 40px;
    align-self: stretch;
    .show-more:hover {
      color: var(--input-normal);
      cursor: pointer;
    }
  }
}

// TEXT INPUT WITH BUTTON (best buds)
.text-input-container {
  border: 1px solid var(--input-normal);
  outline: none;
  position: relative;
  .input-wrapper {
    flex-grow: 1;
    position: relative;
  }
  .input-field {
    border: none;
    padding: 12px 0 12px 12px;
    outline: none;
    width: 100%;
  }
  .input-label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 7px 7px 7px 12px;
    transition: transform 0.2s;
    transform-origin: left bottom;
    pointer-events: none;
    @include media-breakpoint-down(sm) {
      width: 90vw;
    }
  }
  .input-wrapper.focused .input-label {
    transform: translateY(-30px) translateX(-5px);
    background: transparent;
    padding: 0 4px;
    margin: -2px 0;
  }
  .refer-input-btn {
    padding: 8px var(--spacing-unit);
    margin: 8px 12px;
    border: none;
    &:hover {
      opacity: 0.5;
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 70%;
      &:hover {
        background: var(--primary);
      }
    }
  }
}

.shop-now-button {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  &:hover {
    opacity: 0.5;
  }
}

.loyalty {
  @extend .account-preferences;
  .loyalty-status-wrapper {
    .details-button {
      cursor: pointer;
    }
    .points-container {
      max-width: 300px;
      width: 100%;
      .available-header {
        word-spacing: 9999rem;
        display: table-caption;
      }
    }
    @include media-breakpoint-down(sm) {
      overflow: hidden;
    }
  }
}

.order-history {
  @extend .account-preferences;
  min-height: 500px;
  gap: calc(var(--spacing-unit) * 2);
  .order-end-divider {
    gap: calc(var(--spacing-unit) * 2);
    .divider {
      margin: 10px auto;
      width: 50%;
      border: 1px var(--primary) solid;
    }
    .end-text {
      text-wrap: nowrap;
    }
    &[data-loading='true'] {
      position: relative;
      padding: calc(var(--spacing-unit) * 4) 0;
    }
  }
}
